/** @jsx jsx */
import { jsx } from "theme-ui"
import { rem } from "@src/theme"

// Components
import Spacer from "@components/Spacers"
import Container from "@components/Container"

// Assets
import RainbowFlag from "@components/assets/svg/rainbowFlag.svg"
import RainbowHeart from "@components/assets/svg/rainbowHeart.svg"

const ImageThreeUp = ({ blocks }) => (
  <div>
    <Container>
      <Spacer.Medium />
      <div
        sx={{
          display: "flex",
          mx: "auto",
          justifyContent: "space-around",
          flexDirection: ["column", "row"],
        }}
      >
        {blocks.map((block, index) => (
          <div
            key={index}
            sx={{
              display: "flex",
              flexDirection: ["column", null, null, "row"],
              alignItems: ["center"],
              mb: [rem(70), 0, null, 0],
              px: [0, 2, null, 0],
            }}
          >
            <img
              src={block.image}
              alt={block.text}
              sx={{ width: rem(80), height: rem(80), borderRadius: "50%" }}
            />
            <p
              sx={{
                m: 0,
                mt: [rem(15), null, null, 0],
                ml: [0, null, null, rem(28)],
                maxWidth: blocks.length > 2 ? rem(230) : rem(400),
                textAlign: ["center", null, null, "left"],
              }}
            >
              {block.text}
            </p>
          </div>
        ))}
      </div>
    </Container>
    <Spacer.Medium />
  </div>
)

export default ImageThreeUp
