/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"
import { graphql } from "gatsby"
import Layout from "@components/Layouts"
import Section from "@components/Section"
import Container from "@components/Container"
import { rem } from "@src/theme"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import SEO from "@components/seo"
import BannerBlock from "@components/BannerBlock"
import ArticleCard from "@components/ArticleCard"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import {
  ChampionSection,
  ImgSection,
  WhatWeDoSection,
} from "@components/ImpactFooter"
import { getSlug } from "@helpers/utils/getSlug"
import BannerCta from "@components/BannerCta"
import ImageThreeUp from "@components/ImageThreeUp"
import Spacers from "@components/Spacers"
import { Fragment } from "react"
import Spacer from "@components/Spacers"

const CampaignTemplate = props => {
  const { articles, news, resources } = props.data
  const { frontmatter } = props.data.campaignData.edges[0].node

  resources.edges.forEach(item => {
    item.linkPrefix = 'resources'
  })

  news.edges.forEach(item => {
    item.linkPrefix = 'news'
  })

  articles.edges.forEach(item => {
    item.linkPrefix = 'articles'
  })

  let cards = [...resources.edges, ...news.edges, ...articles.edges]
  cards.sort((a, b) => {
    return new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
  })
  cards = cards.slice(0, 4)

  const {
    campaignName,
    header,
    bannerCta,
    bannerImage,
    imageTextBlocks,
    imageTextRow,
    showToolkit,
    ogimage,
    ogdescription,
  } = frontmatter

  const titleMap = {
    "trans awareness week": "Trans Awareness Week",
    "wear it purple day": "Wear It Purple Day",
  }

  return (
    <Layout
      border={campaignName === "trans awareness week" ? "trans" : undefined}
    >
      <SEO
        title={titleMap[campaignName]}
        pathname={props.location.pathname}
        image={ogimage}
        description={ogdescription}
      />
      <TwoColumnSection
        col1={
          <div>
            <ColumnBlock
              styles={{
                bg: "purple",
                color: "pink",
              }}
            >
              <CTAHeadingBlock heading={header.heading} />
            </ColumnBlock>

            <ColumnBlock
              styles={{
                bg: "pink",
                color: "darkPurple",
                height: "100%",
              }}
            >
              <CTAHeadingBlock
                heading={header.subheading}
                body={header.subtext}
                downloadResource={showToolkit}
                downloadLabel={"Download your toolkit"}
                downloadTag={`${campaignName} toolkit`}
                smallHeading
              />
            </ColumnBlock>
          </div>
        }
        // stackedCol1
        col2={<ColumnImageBlock image={header.image} />}
      />
      <ImageThreeUp blocks={imageTextRow} />

      {!showToolkit && bannerImage && bannerImage.image ? (
        <Fragment>
          <img
            src={bannerImage.image}
            alt={bannerImage.text}
            sx={{
              width: "100%",
              display: "block",
            }}
          />
          <Spacers.Large />
        </Fragment>
      ) : null}

      {showToolkit ? (
        <BannerCta
          heading={bannerCta.heading}
          text={bannerCta.text}
          cta={{
            url: `toolkit-download`,
            text: "Download your toolkit",
            downloadTag: `${campaignName} toolkit`,
          }}
          img={{
            src: bannerCta.image,
            alt: bannerCta.heading,
          }}
          backgroundImg={bannerCta.backgroundImg}
        />
      ) : null}

      <ImageTextBlocksList blocks={imageTextBlocks} noPt={!showToolkit} />
      <Spacer.Large />

      <Section
        sx={{
          bg: "pink",
        }}
      >
        <BannerBlock title="Further resources" noMt />
        <Container>
          <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
            {cards.map(item => (
              <ArticleCard
                linkPrefix={item.linkPrefix}
                key={item.node.id}
                data={{
                  ...item,
                  slug: getSlug(item),
                  preview: item.node.frontmatter.cardPreview,
                }}
              />
            ))}
          </Grid>
        </Container>
      </Section>
      <ChampionSection />
      <WhatWeDoSection />

      <ImgSection />
    </Layout>
  )
}

export default CampaignTemplate

export const pageQuery = graphql`
  query($name: String!, $articlesTag: String!) {
    campaignData: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/posts/campaigns/*.md" }
        frontmatter: { campaignName: { eq: $name } }
      }
    ) {
      edges {
        node {
          frontmatter {
            campaignName
            showToolkit
            header {
              ...Header
            }
            imageTextRow {
              text
              image
            }
            bannerCta: toolkitCTA {
              ...ToolkitCTA
            }
            imageTextBlocks {
              ...ImageTextBlocks
            }
            bannerImage {
              text
              image
            }
            ogimage
            ogdescription
          }
        }
      }
    }
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/article/*.md" }
        frontmatter: { tags: { in: [$articlesTag] } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            cardPreview
            tags
            image
          }
        }
      }
    }
    news: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/news/*.md" }
        frontmatter: { tags: { in: [$articlesTag] } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            cardPreview
            tags
            image
          }
        }
      }
    }
    resources: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/resource/*.md" }
        frontmatter: { tags: { in: [$articlesTag] } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 4
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            cardPreview
            tags
            image
          }
        }
      }
    }
  }
`
