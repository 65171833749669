/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"
import theme, { rem } from "@src/theme"
import { connect } from "react-redux"
import { Themed } from '@theme-ui/mdx'


// Components
import { Grid } from "@theme-ui/components"
import Section from "@components/Section"
import Container from "@components/Container"
import Button from "@components/Button"
import { BodyLarge } from "@components/primitives/Text"
import Link from "@components/Link"
import Spacing from "@components/Spacers"

const BannerCta = ({
  heading,
  text,
  cta,
  img,
  backgroundImg,
  openMailchimpModal,
  setMailchimpTag,
  imageFit = 'cover',
}) => (
  <Section
    sx={{
      backgroundImage: `url(${backgroundImg})`,
      backgroundSize: "cover",
      backgroundColor: theme.colors.darkPurple,
    }}
  >
    <Container>
      <Grid
        columns={[1, 2]}
        gap={0}
        sx={{
          maxWidth: rem(800),
          m: "auto !important",
          backgroundColor: "white",
          p: rem(24),
        }}
      >
        <div
          sx={{
            height: [rem(200), rem(300)],
            width: "100%",
            m: "auto",
          }}
        >
          <img sx={{
            objectFit: `${imageFit} !important`,
          }} src={img.src} alt={img.alt} className="object-fit" />
        </div>

        <div
          sx={{
            textAlign: "center",
            maxWidth: rem(450),
            m: "auto",
            p: rem(24),
          }}
        >
          <Themed.h3
            sx={{
              m: 0,
            }}
          >
            {heading}
          </Themed.h3>
          <Spacing.XSmall />
          <BodyLarge style={{ m: 0 }}>{text}</BodyLarge>
          <Spacing.Small />
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {cta && cta.url === "toolkit-download" ? (
              <Button
                onClick={() => {
                  openMailchimpModal()
                  setMailchimpTag(cta.downloadTag)
                }}
              >
                {cta.text}
              </Button>
            ) : cta ? (
              <Link to={cta.url}>{cta.text}</Link>
            ) : null}
          </div>
        </div>
      </Grid>
    </Container>
  </Section>
)

export default connect(null, dispatch => ({
  openMailchimpModal: () => dispatch.mailchimpModal.toggleModal(true),
  setMailchimpTag: tag => dispatch.mailchimpModal.setMailchimpTag(tag),
}))(BannerCta)

BannerCta.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  backgroundImg: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    downloadTag: PropTypes.string,
  }),
}
